import React from 'react';
import { Col, Image } from "react-bootstrap"; // assuming Image is a separate component
import {Link, Outlet, useOutletContext} from "react-router-dom";
import Menu, { AccessibilityState, getContrastClassName } from '../Menu';

export interface RectangleCardProps {
  className?: string;
  title?: string;
  title_html?: string;
  imageSrc?: string;
  children?: React.ReactNode;
  paragraphs?: {
    text?: string;
    html?: string;
    onClick?: () => void;
    className?: string;
  }[];
  onClick?: () => void;
}

export const RectangleCard: React.FC<RectangleCardProps> = ({
  className,
  title,
  title_html,
  imageSrc,
  children,
  paragraphs,
  onClick,
}) => {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
        , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
       } = contextOutlet;

  return (
    <Col className={className}>
      <div className="main_row home-contacts-row">
        {(title && !title_html) && <h2>{title}</h2>}
        {title_html && (
            <span dangerouslySetInnerHTML={{ __html: title_html }} />
          )}
        {(fontScale == 100 && getContrastClassName(contrast) == '' && showImages && imageSrc) && (
          <Image src={imageSrc} width={50} height={50} />
        )}
      </div>
      {children}
      {paragraphs?.map((paragraph, index) => (
        <p key={index} onClick={paragraph.onClick} className={paragraph.className}>
          {paragraph.text}
          {paragraph.html && (
            <span dangerouslySetInnerHTML={{ __html: paragraph.html }} />
          )}
        </p>
      ))}
    </Col>
  );
};

//export default RectangleCard;