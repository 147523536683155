import { AccessibilityState, getContrastClassName } from './Menu';
import { useOutletContext } from 'react-router-dom';
import { RectangleCardGrid } from './shared';


const Footer = () => {
  const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

  const currentYear = new Date().getFullYear();

  const cards = [
    {
      className: 'card-rectangle blue-rectangle left-rectangle',
      title: 'Адрес',
      imageSrc: '/icons/map.svg',
      children: 
      <>
        <p>г. Москва, ул. Никольская, д. 10, офис 601</p>
        <p><a href="https://yandex.ru/maps/-/CDSXQB6D" target="_blank" rel="noreferrer" className='map'>Перейти на карту</a></p>
      </>
    },
    {
      className: 'card-rectangle blue-rectangle right-rectangle',
      title: 'Контакты',
      imageSrc: '/icons/mail.svg',
      children: 
        <p>
          <a href="tel:+7 (495) 221-69-91" className="text-decoration-none">+7 (495) 221-69-91</a>
          <br/><br/><a href="mailto:info@indemp.ru" className="text-decoration-none">info@indemp.ru</a>
        </p>
    },
    {
        className: 'card-rectangle blue-rectangle left-rectangle',
        children: 
        <p>
          <a>© {currentYear} Институт демографической политики имени Д. И. Менделеева</a>
          <br/><a href="https://minobrnauki.gov.ru/" className="text-decoration-none">Министерство науки и высшего образования Российской Федерации</a>
          <br/><a href="https://edu.gov.ru/" className="text-decoration-none">Министерство просвещения Российской Федерации</a>
        </p>
      },
  ];   

  return (
    <footer style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)}`}>
      <section className="home-contacts-section">
        <div className="main_container" style={{paddingBottom: '25px'}}>
          <h1>КОНТАКТЫ</h1>
          <RectangleCardGrid cards={cards} />
        </div>
      </section>

      {/*<section className='home-contacts-section' style={{marginBottom: '0px'}}>
          <div className='main_container' style={{paddingBottom: '25px'}}>
            <h1>КОНТАКТЫ</h1>
            <div className='main_row'>
              <Col className='card-rectangle white-rectangle left-rectangle'>
                <div className="main_row home-contacts-row">
                  <h2>Адрес</h2>
                  {(fontScale==100 && getContrastClassName(contrast)=='' && showImages) && <Image src="icons/map.svg" width={50} height={50} />}
                </div>
                <p>г. Москва, ул. Никольская, д. 10, офис 601</p>
                <p><a href="https://yandex.ru/maps/-/CDSXQB6D" className='map'>Перейти на карту</a></p>
              </Col>
              <Col className='card-rectangle white-rectangle right-rectangle'>
                <div className="main_row home-contacts-row">
                  <h2>Контакты</h2>
                  {(fontScale==100 && getContrastClassName(contrast)=='' && showImages) && <Image src="icons/mail.svg" width={50} height={50} />}
                </div>
                <p><a href="tel:+74952216991">+7 (495) 221-69-91</a></p>
                <p><a href="mail:info@indemp.ru">info@indemp.ru</a></p>
              </Col>
            </div>
          </div>
        </section>
      
      <div className='main_container' style={{paddingTop: '0px', paddingBottom: '0px'}}>
        <div className='card-rectangle white-rectangle'>
            <p><a>© {currentYear} Институт демографической политики имени Д. И. Менделеева</a></p>
            <p><a href="https://minobrnauki.gov.ru/">Министерство науки и высшего образования Российской Федерации</a></p>
            <p><a href="https://edu.gov.ru/">Министерство просвещения Российской Федерации</a></p>
        </div>
      </div>*/}

    </footer>
    );
};

export default Footer;