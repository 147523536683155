import React, { useEffect, useState } from 'react';
import { FaFile, FaAdjust } from 'react-icons/fa';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Modal, Form, Button, ButtonGroup } from 'react-bootstrap';
import { PiEyeFill, PiEyeSlashFill } from "react-icons/pi";
import { TbTypographyOff } from "react-icons/tb";
import { TbEyeglassOff } from "react-icons/tb";
import { CiImageOff } from "react-icons/ci";
import { CiImageOn } from "react-icons/ci";
import {ContrastConst} from "../const"
import { Outlet, Link, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import {ReactComponent as Logo} from './logo.svg'

export const getContrastClassName = (contrast: number) => {
  switch (contrast) {
    case 1:
      return 'contrast-white-black';
    case 2:
      return 'contrast-black-white';
    case 3:
      return 'contrast-blue';
    case 4:
      return 'contrast-beige-brown';
    case 5:
      return 'contrast-brown-yellow-green';
    case 0:
      return '';
  }
};

export interface AccessibilityState {
  showAccessibilityOptions: boolean;
  fontScale: number;
  contrast: number;
  showImages: boolean;
  setShowAccessibilityOptions: (showAccessibilityOptions: boolean) => void;
  setFontScale: (scale: number) => void;
  setContrast: (contrast: number) => void;
  setShowImages: (showImages: boolean) => void;
}

const Menu: React.FC = () => {
  const [showAccessibilityOptions, setShowAccessibilityOptions] = useState(false)
  const [fontScale, setFontScale] = useState(100)
  const [contrast, setContrast] = useState(ContrastConst.CONTRASTDEFAULT)
  const [showImages, setShowImages] = useState(true)

  const changeFontSize = (scale: number) => {
    setFontScale(scale);
  };

  const changeContrast = (contrast: number) => {
    setContrast(contrast);
  };

  const changeShowImages = (showImages: boolean) => {
    setShowImages(showImages);
  };

  const clearAccessibilityOptions = () => {
    setShowImages(true);
    setContrast(ContrastConst.CONTRASTDEFAULT);
    setFontScale(100)
  }

  const [isOpen, setIsOpen] = useState(true);
  
    const location = useLocation();

    // Toggle function for non-mobile devices
    const toggleNavbar = () => setIsOpen(!isOpen);

    // Close the navbar
    const closeNavbar = () => {
      if(window.innerWidth < 1200) setIsOpen(false)
    };

    // Close the navbar when the location changes and it's a mobile device
    useEffect(() => {
        // Check if the current screen size is mobile
        const isMobile = window.innerWidth < 1200;
        if (isMobile) {
          setIsOpen(false)
        }
        window.scrollTo(0, 0);
        console.log(isOpen);
    }, [location]);

    // Add a resize event listener to close the navbar on window resize (optional)
    useEffect(() => {
      const handleResize = () => {
          if (window.innerWidth >= 1200) {
              setIsOpen(true);
          }
      };
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
    }, []);

  return (
  <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)}`}>
    <div className='menu'>
    {showAccessibilityOptions &&
    <Navbar expand="xl" className='menu-space menu-accesibility' style={{padding: '10px'}}>
      <div className='main_container'>
        <ButtonGroup aria-label="Basic example" className='accesibility-A mb-2'>
          <Button title='Стандартный размер текста' variant="light" style={{ fontSize: '75%'}} onClick={() => changeFontSize(100)}>
            A
          </Button>
          <Button title='Средний размер текста' variant="light" style={{ fontSize: '100%'}} onClick={() => changeFontSize(125)}>
            A
          </Button>
          <Button title='Большой размер текста' variant="light" style={{ fontSize: '150%' }} onClick={() => changeFontSize(150)}>
            A
          </Button>
        </ButtonGroup>
        <ButtonGroup aria-label="Basic example" className='accesibility-С mb-2'>
          <Button title='Белый фон, черный текст' variant="light" style={{ color: '#000', backgroundColor: '#fff' }} onClick={() => changeContrast(ContrastConst.CONTRASTWHITEBLACK)}>
            Ц
          </Button>
          <Button title='Черный фон, белый текст' variant="light" style={{ color: '#fff', backgroundColor: '#000' }} onClick={() => changeContrast(ContrastConst.CONTRASTBLACKWHITE)}>
            Ц
          </Button>
          <Button title='Голубой фон, синий текст' variant="light" style={{ color: '#195183', backgroundColor: '#9dd1ff' }} onClick={() => changeContrast(ContrastConst.CONTRASTBLUE)}>
            Ц
          </Button>
          <Button title='Бежевый фон, коричневый текст' variant="light" style={{ color: '#7c3c00', backgroundColor: '#f7f3d6' }} onClick={() => changeContrast(ContrastConst.CONTRASTBEIGEBROWN)}>
            Ц
          </Button>
          <Button title='Коричневый фон, зеленый текст' variant="light" style={{ color: '#a9e44d', backgroundColor: '#3b2716' }} onClick={() => changeContrast(ContrastConst.CONTRASTBROWNYELLOWGREEN)}>
            Ц
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button 
          title='Показать фотографии'
          variant="light" 
          style={{ fontSize: '150%', border: 'none', borderRadius:'0px', color: 'inherit', backgroundColor: 'inherit', width: '4rem'}}
          onClick={() => changeShowImages(true)}
          >
            <CiImageOn />
          </Button>
          <Button 
          title='Скрыть фотографии'
          variant="light" 
          style={{ fontSize: '150%', border: 'none', borderRadius:'0px', color: 'inherit', backgroundColor: 'inherit', width: '4rem'}}
          onClick={() => changeShowImages(false)}
          >
            <CiImageOff />
          </Button>
        </ButtonGroup>

        <Button 
          title='Открыть обычную версию сайта'
          variant="light" 
          style={{ fontSize: '150%', border: 'none', borderRadius:'0px', color: 'inherit', backgroundColor: 'inherit', marginRight: '1rem', width: '4rem'}}
          onClick={() => clearAccessibilityOptions()}
          >
            {(fontScale!=100 || showImages!=true || contrast!=ContrastConst.CONTRASTDEFAULT) && <TbTypographyOff />}
          </Button>
      </div>
    </Navbar>
    }

    <Navbar expand="xl"  className='menu-space menu-rectangle' style={{padding: '0'}} expanded={isOpen}>
      <div className='main_container'>
      <div className='main_row'>
        <div className='brand_toggle'>
          <Navbar.Brand>
            <Link to='/'>
              <Logo />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => {
            setIsOpen(!isOpen)
            console.log(isOpen)
            }}/>
        </div>
        {isOpen && 
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
            <Nav className='justify-content-space-around'>
              <NavDropdown title="Об организации" id="basic-nav-dropdown">
                <NavDropdown.Item><Link to='/organization'>Основные сведения</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/structure'>Структура</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/document'>Документы</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/education'>Образование</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/paidservice'>Платные образовательные услуги</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/placesadmission'>Вакантные места</Link></NavDropdown.Item>
              </NavDropdown>
              <Nav.Link><Link to='/managers'>Руководство</Link></Nav.Link>
              <NavDropdown title="Ресурсы" id="basic-nav-dropdown">
                <NavDropdown.Item><Link to='/equipment'>Оснащённость образовательного процесса</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/financialactivity'>Финансово-хозяйственная деятельность</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/accessibleenvironment'>Доступная среда</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/internationalcooperation'>Международное сотрудничество</Link></NavDropdown.Item>
              </NavDropdown>
              <Nav.Link><Link to='/conferences'>Конференции</Link></Nav.Link>
              <Nav.Link href="tel:+74952216991">+7 (495) 221-69-91</Nav.Link>
              <Nav.Link onClick={() => setShowAccessibilityOptions(!showAccessibilityOptions)}>
              {showAccessibilityOptions ? <PiEyeSlashFill size={26} className='menu_eye' /> : <PiEyeFill size={26} className='menu_eye' />}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        }
      </div>
     </div>
    </Navbar>
    </div>

    

    <Outlet context={{showAccessibilityOptions, fontScale, contrast, showImages, setFontScale, setContrast, setShowAccessibilityOptions, setShowImages}} />

  </div>);
};

export default Menu;
