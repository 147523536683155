import { useOutletContext } from "react-router-dom";
import { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';
import { PageHeaderWithIcon, RectangleCardGrid } from "./shared";
import { ConferencePageProps } from '../components/shared/ConferencePage';

import { useEffect, useState } from "react";
import axios from "axios";

function Conferences() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

    const [conferences, setConferences] = useState<ConferencePageProps[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');
    
    const API_BASE_URL = window.config?.API_BASE_URL ?? 'http://localhost:8080';

    useEffect(() => {
        const fetchConferences = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/conferences`);
                setConferences(response.data); // Получаем записи из ответа
                } catch (err) {
                if (axios.isAxiosError(err)) {
                // Это ошибка Axios
                setError(`Ошибка при загрузке данных: ${err.response?.data?.message || err.message}`);
                } else {
                // Любая другая ошибка
                setError('Ошибка при загрузке данных');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchConferences();
    }, []); 


    const cards = [...conferences.sort((a, b) => b.id - a.id).map((conference: ConferencePageProps) => ({
        className: 'card-rectangle blue-rectangle',
        title: conference.title,
        title_html: `<a href="/conference/${conference.id}" rel="noopener noreferrer" class="h2-a">${conference.title}</a>`,
          imageSrc: "icons/student.svg",
          paragraphs: [
            {
              text: conference.endDate ? conference.startDate + ' - ' + conference.endDate : conference.startDate,
            },
            conference.website ? {
              text: 'Сайт конференции: ',
              html: `<a href="https://www.${conference.website}" class="link">${conference.website}</a>`,
                onClick: () => window.open(`https://www.${conference.website}`, '_blank'),
            } : {
                text: ''
            },
            {
                  text: 'Скачать программу',
                  onClick: () => window.open(conference.program, '_blank'),
                  className: 'save_doc',
            },
          ].filter((paragraph) => paragraph.text.trim() !== ''),
    }))];


    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
            

                <PageHeaderWithIcon
                    header="Конференции"
                    navigation="Главная / Конференции"
                    iconSrc="/header_icons/Conference.svg"
                />

                <section className="card-section">
                    <div className="main_container">
                        <RectangleCardGrid cards={cards} fullWidth />
                    </div>
                </section>


                <Footer/>
            </div>
        </div>
    )
}

export default Conferences;